
import {computed, defineComponent, ref} from "vue";
import {
    IonContent,
    IonPage,
    IonLabel,
    IonInput,
    IonItem
} from "@ionic/vue";
import {execute} from "@/mixins/LoadingMixin";
import FixedFooter from "@/components/FixedFooter.vue";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import router from "@/router";
import {EMPTY_GUID} from "@/utils/GuidUtil";
import {presentValidationAlert} from "@/services/Notify";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {Partner} from "@/models/PartnerModel";
import {createPartner, getPartner, updatePartner} from "@/services/PartnerService";

export default defineComponent({
    name: "Partner",
    components: {
        IonContent,
        IonPage,
        IonLabel,
        IonInput,
        IonItem,
        FixedFooter
    },
    props: {
        id: {
            type: String,
            default: EMPTY_GUID
        }
    },
    async ionViewWillEnter() {
        await this.$store.dispatch('app/setPageTitle', this.editing ? this.language.editPartner : this.language.addPartner);
        if (this.editing) {
            await execute(async () => {
                this.partner = await getPartner(this.id);
            }, this.language.errors.gettingPartner);
        } else {
            this.partner = new Partner();
        }
    },
    setup(props: any) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const partner = ref(new Partner());
        const editing = computed(() => props.id !== EMPTY_GUID);
        
        const validate = (): boolean => {
            let message = '';
            if (partner.value.name.trim() === '') {
                message = language.value.validation.enterName;
            } else if (partner.value.customId.trim() === '') {
                message = language.value.validation.enterId;
            }
            
            if (message) {
                presentValidationAlert(message);
                return false;
            }
            return true;
        }
        
        const save = async () => {
            if (validate()) {
                await execute(async () => {
                    if (editing.value) {
                        await updatePartner(partner.value);
                    } else {
                        partner.value.id = uuid4();
                        await createPartner(partner.value);
                    }
                    await router.replace({ name: 'partners' });
                }, language.value.errors.savingPartner);
            }
        }
        
        return {
            editing,
            language,
            partner,
            footerButtons: computed(() => 
                new FooterButtonsBuilder()
                .addClearButton(false, () => router.back(), language.value.cancel)
                .addPrimaryButton(false, async () => await save(), editing.value ? language.value.saveEdits : language.value.addPartner)
                .create()
            )
        }
    }
});
