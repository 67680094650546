import router from "@/router";
import {FooterButton} from "@/models/FooterButtonsModel";
import {store} from "@/store/Store";

export class FooterButtonsBuilder {
    footerButtons = [] as FooterButton[];
    class = 'custom-button';
    language = store.getters['app/language'];

    addClearBackButton() {
        return this.addClearButton(true, () => router.back(), this.language.back);
    }

    addClearButton(hasIcon: boolean, handler: Function, text: string) {
        this.footerButtons.push({
            class: this.class + ' button-clear',
            icon: hasIcon,
            iconPos: 'start',
            handler: () => handler(),
            text: text
        })
        return this;
    }
    
    addPrimaryNextButton(handler: Function) {
        return this.addPrimaryButton(true, handler, this.language.next);
    }

    addPrimaryButton(hasIcon: boolean, handler: Function, text: string) {
        this.footerButtons.push({
            class: this.class + ' button-primary',
            icon: hasIcon,
            iconPos: 'end',
            handler: () => handler(),
            text: text
        })
        return this;
    }

    create() {
        return this.footerButtons;
    }
}