import {GET, POST, PUT} from './HTTP';
import {Partner} from "@/models/PartnerModel";

export function getPartners(): Promise<Partner[]>{
    return GET('/partners');
}

export function getPartnersWithAdmins(): Promise<Partner[]>{
    return GET('/partners/admins');
}

export function getPartner(id: string): Promise<Partner>{
    return GET(`/partners/${id}`);
}

export function updatePartner(partner: Partner): Promise<any>{
    return PUT(`/partners/${partner.id}`, partner);
}

export function createPartner(partner: Partner): Promise<any>{
    return POST('/partners', partner);
}
 